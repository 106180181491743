.page{
  header {
    .top_header {
      background-size: cover;
      .headline {
        color: white;
        &.black {
          color: black;
        }
        a {
          background: url('../../images/logo_white.svg') center center transparent no-repeat scroll;
        }
      }
      nav {
        ul {
          li {
            &:before {
              content: '|';
              color: white;
            }
            a {
              color: white;
              &.black {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  .contact{
    h2{
      text-align: left;
    }
  }
}