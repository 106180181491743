.home {
  header {
    @media (max-width: 767px) {
      height: 370px;
    }
    background: url('../../images/header_home.png') 0 0 no-repeat scroll transparent;
    background-size: cover;
    height: 640px;
    overflow: hidden;
    .top_header {
      box-shadow: none;
      position: fixed;
      top: 0;
      left: 0;
      .vignette {
        margin-top: -5px;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        border-radius: 200px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        background-position: center center;
        background-repeat: repeat;
        .avatar {
          border-radius: 200px;
          height: 100%;
          width: 100%;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .row {
        min-height: 50px;
        .headline {
          display: none;
          @include transition(all, .5s, ease);
        }
        nav {
          ul {
            li {
              font-size: 10px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 3.1px;
              text-align: left;
              &:before {
                color: white;
              }
              a {
                color: white;
              }
            }
          }
        }
      }
    }
    .top_header.background {
      @include transition(all, .5s, ease);
      background-color: $black;
      .headline {
        display: flex;
        @include transition(all, .5s, ease);
      }
    }
    #video-fond {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 0;
        video {
          @media screen and (min-width: 992px) {
            transform: scale(2);
          }
          @media screen and (max-width: 991px) {
            transform: scale(3);
          }
      }
      .punchline {
        margin-top: 159px;
        @media(max-width: 767px) {
          margin-top: 20px;
          .row + .row {
            margin-top: 1rem;
          }

        }
        float: left;
        width: 100%;
        text-align: center;
        z-index: 6;
        position: absolute;
        @media screen and (max-width: 767px) {
          .logo{
            max-width: 150px;
            display: block;
            margin: 0 auto;
          }
        }
        img {
          display: inline-block;
        }
        h1 {
          float: left;
          width: 100%;
          font-family: $playfair;
          font-weight: normal;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-align: center;
          color: white;
          font-size: 30px;
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
        form {
          @media(min-width: 1200px) {
            align-items: center;
            display: flex;
            justify-content: center;
            padding-bottom: 60px;
          }

          .form-group {
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              width: 100%;
              height: 44px;
              outline: none;
              border: none;
              box-shadow: none;
              @media screen and (min-width: 768px) and (max-width: 1199px) {
                margin-bottom: 15px;
              }
            }
            span {
              color: white;
              margin: 0 10px;
              font-size: 34px;
              font-weight: 100;
            }
            .btn-search.btn-submit {
              @media screen and (max-width: 767px) {
                font-size: 20px;
                border-radius: 200px;
                padding: 10px 12px;
                margin-left: 5px;
              }
            }
            .btn-submit {
              justify-content: center;
              display: flex;
              align-items: center;
              outline: none;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 30px;
              padding-right: 30px;
              &.btn-image {
                padding-right: 50px;
              }
              img {
                height: 20px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

}

header {
  /*width: 100%;
  float: left;
  position: relative;*/
  z-index: 10;
  .top_header.active {
    left: -225px;
    @include transition(all, .5s, ease);
  }
  .top_header {
    @include transition(all, .5s, ease);
    background-size: cover;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
    font-family: $raleway;
    font-weight: bold;
    width: 100%;
    padding: 10px 0;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    .vignette {
      margin-top: -5px;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
      border-radius: 200px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      background-position: center center;
      background-repeat: repeat;
      .avatar {
        border-radius: 200px;
        height: 100%;
        width: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .row {
      display: flex;
      align-items: center;
      min-height: 50px;
      .headline {
        color: white;
        &.black {
          color: black;
          a {
            background: url('../../images/logo_black.svg') center center transparent no-repeat scroll;
          }
        }
        a {
          background: url('../../images/logo_white.svg') center center transparent no-repeat scroll;
          min-width: 55px;
          float: left;
          height: 50px;
          margin-right: 15px;
        }
        display: flex;
        align-items: center;
        span {
          float: left;
          font-style: italic;
          font-weight: normal;
        }
      }
      nav {
        float: right;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          > li {
            float: left;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 12px;
            letter-spacing: 2px;
            text-align: left;
            &:before {
              content: '|';
              color: white;
              float: left;
              line-height: 15px;
            }
            a {
              color: white;
              margin: 0 10px;
              font-size: 12px;
              text-decoration: none;
              .flag-icon {
                margin-left: 5px;
              }
              &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
              &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
              &.black {
                color: black;
              }
            }
            .dropdown {
              .dropdown-menu {
                right: 0;
                left: auto;
                li {
                  width: 100%;
                  &:before {
                    display: none;
                  }
                  a {
                    color: black;
                    font-weight: bold;
                    padding: 5px;
                  }
                }
              }
            }
          }
          li.black {
            float: left;
            text-transform: uppercase;

            &:before {
              content: '|';
              color: black !important;
              float: left;
            }
            a {
              color: black;
              margin: 0 15px;
              font-size: 12px;
              text-decoration: none;
              .flag-icon {
                margin-left: 5px;
              }
              &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
              &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
            .dropdown {
              .dropdown-menu {
                right: 0;
                left: auto;
                li {
                  width: 100%;
                  &:before {
                    display: none;
                  }
                  a {
                    color: black;
                    font-weight: bold;
                    padding: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .streamer {
    float: left;
    width: 100%;
    position: relative;
    text-align: center;
    color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 350px;
    display: flex;
    align-items: center;
    h2 {
      text-transform: uppercase;
      font-weight: bold;
    }
    .icon {
      float: left;
      width: 100%;
      text-align: center;
      svg {
        height: 70px;
        overflow: visible;
        g {
          fill: black;
        }
      }
    }
    h1 {
      font-style: italic;
      font-weight: bold;
    }
  }

  .navigation.active {
    right: 0;
    @include transition(all, .5s, ease);
  }
  .navigation {
    background-color: black;
    height: 100vh;
    position: fixed;
    right: -225px;
    top: 0;
    width: 225px;
    z-index: 99999;
    @include transition(all, .5s, ease);
    .row + .row {
      margin-top: 1rem;
    }
    .row {
      &:first-child {
        padding-top: 35px;
      }
      &:last-child {
        padding-bottom: 15px;
      }
      .times {
        color: white;
        font-size: 36px;
        top: -25px;
        right: 15px;
        position: absolute;
      }
      .nav-logo {
        height: 60px;
        width: 60px;
      }
      .nav-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        .language-li {
          color: white;
          font-size: 12px;
          margin-top: 25px;
          a {
            color: white;
            letter-spacing: 1px;
          }
          .dropdown-menu {
            a {
              font-size: 12px;
              color: black;
            }
          }
        }
        .nav-li {
          font-weight: 500;
          letter-spacing: 3.1px;
          margin-top: 10px;
          .nav-li-a {
            color: white;
            text-transform: uppercase;
            text-decoration: none;
          }
        }
        #nav-li {
          margin-top: 30px;
        }
        .divider {
          background-color: white;
          display: block;
          height: 1px;
          margin-top: 25px;
          width: 100%;
        }
        .contact-nav-li {
          font-weight: 500;
          letter-spacing: 3.1px;
          margin-top: 10px;
          .contact-nav-li-a {
            color: $grey_9b;
            text-transform: uppercase;
          }
          .contact-nav-li-a:hover {
            text-decoration: none;
          }
          .contact-nav-li-a:active {
            text-decoration: none;
          }
        }
        #contact-nav-li {
          margin-top: 25px;
        }
        .follow {
          color: white;
          font-family: $raleway;
          font-size: 18px;
          font-style: italic;
          font-stretch: normal;
          letter-spacing: 2px;
          margin-top: 40px;
        }
        .rs-follow {
          display: inline-block;
          float: left;
          list-style-type: none;
          margin-top: 15px;
          padding-left: 0;
          .rs-li {
            display: inline-block;
            .rs-a {
              background-color: white;
              border-radius: 15px;
              color: black;
              display: block;
              float: left;
              height: 31px;
              margin-right: 15px;
              text-align: center;
              width: 30px;
              .icon {
                font-size: 23px;
                padding-top: 4px;
              }
            }
          }
        }
      }
    }
  }
}
