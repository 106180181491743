.ambassador {
  h1 {
    font-size: 36px;
    font-weight: normal !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: 0.4px;
    text-align: center;
    color: white;
  }
  .content_ambassador {
    .description {
      font-size: 20px;
      @media screen and (max-width: 767px){
        font-size: 14px;
      }
    }
    .contact {
      text-align: center;
    }
  }
  .search-nav {
    background-color: $grey_light;
    display: block;
    height: 82px;
    left: -10%;
    position: relative;
    top: 0;
    width: 125%;
    .nav-logo {
      height: 49px;
      left: 24px;
      position: fixed;
      top: 15px;
      width: 49px;
    }
    h1 {
      color: white;
      display: block;
      font-size: 11px;
      font-family: $playfair;
      font-style: italic;
      position: fixed;
      left: 86px;
      top: 0;
      width: 114px;
    }
  }
  .streamer {
    padding: 30px 0;
    .vignette {
      cursor: pointer;
      position: relative;
      border-radius: 200px;
      padding: 10px;
      width: 135px;
      height: 135px;
      display: flex;
      align-items: center;
      margin: 0 auto 15px auto;
      background-position: center center;
      background-repeat: repeat;
      .avatar {
        border-radius: 200px;
        height: 100%;
        width: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .list_themes {
      float: left;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-white {
        margin-right: 10px;
        &:last-child{
          margin: 0;
        }
      }
    }
  }
  .white {
    color: white;
  }
  .content_ambassador {
    .contact {
      text-align: center;
      a {
        margin: 30px auto 0 auto;
      }
    }
  }
  .friends {
    margin-bottom: 40px;
    h2 {
      font-size: 30px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      color: #565656;
    }
  }
  .experiences {
    h5 {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-align: center;
    }
    .localization {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-align: center;
    }
  }
  .btn-contact {
    padding: 17px 39px;
  }
  .div-end {
    margin-top: 40px;
  }

}