html {
  font-size: 14px;
}

body {
  font-family: $raleway;
  font-size: 14px;
  position: relative;
  padding-top: 70px;
  @include transition(all, .5s, ease);
  left: 0;
}

body.menu_active {
  left: -225px;
  @include transition(all, .5s, ease);
}

h1, h2, h3, h4, h5, h6 {
  float: left;
  width: 100%;
}

iframe {
  border: none;
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 20px;
  }
}

h6 {
  font-size: 14px;
}

.row + .row {
  margin-top: 2rem;
}

.alerts {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;

  .alert {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.content {
  float: left;
  width: 100%;
  position: relative;
}

.section {
  padding: 30px 0;
  float: left;
  width: 100%;
  position: relative;

  h2 {
    font-weight: normal;
    text-align: center;
    margin: 20px 0;
  }

  .carousel {
    .inner {
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: baseline;
        justify-content: center;
      }
      text-align: center;
      color: $grey_56;

      .friend {
        @media screen and (min-width: 768px) {
          flex: 1;
          max-width: 25%;
        }
        text-align: center;

        .vignette {
          cursor: pointer;
          position: relative;
          border-radius: 200px;
          padding: 15px;
          width: 164px;
          height: 164px;
          @media screen and (max-width: 767px) {
            width: 100px;
            height: 100px;
          }
          display: flex;
          align-items: center;
          margin: 0 auto 15px auto;
          background-position: center center;
          background-repeat: repeat;

          .avatar {
            border-radius: 200px;
            height: 100%;
            width: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        .description {
          position: relative;
          float: left;
          width: 100%;

          span {
            float: left;
            width: 100%;
            text-transform: capitalize;
          }

          .name {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            text-align: center;
            color: $grey_56;
          }

          .city {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            text-align: center;
            color: $grey_56;
          }
        }

        .actions {
          margin-top: 15px;
          float: left;
          width: 100%;
          position: relative;

          .section {
            padding: 15px 0;

            .tag_theme {
              @media screen and (max-width: 767px) {
                font-size: 10px;
              }
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 3.6px;
              padding-top: 10px;
              text-align: center;
              color: black;
            }

            .btn {
              padding: 5px 30px 5px 30px;
              font-size: 14px;
              @media screen and (max-width: 767px) {
                padding: 5px 20px;
                font-size: 12px;
              }
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 3px;
              text-align: center;
              color: white;
            }
          }

          .section.themes {
            display: grid;

            span {
              max-width: 100%;
              margin: 0 auto 5px auto;
            }
          }
        }
      }

    }

    .carousel-control {
      color: $grey_56;
      opacity: 1;
      background: none;

      .fa-angle-right {
        margin-right: -10px;
        width: 50px;
        height: 50px;
        margin-top: -10px;
        font-size: 50px;
        right: 0%;
      }

      .fa-angle-left {
        margin-left: -10px;
        width: 50px;
        height: 50px;
        margin-top: -10px;
        font-size: 50px;
        left: 0%;
      }

      .fa-angle-right, .fa-angle-left {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        z-index: 5;
        display: inline-block;
      }
    }
  }

}

.btn-submit {
  color: white;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 500;
  background-color: $red;
  border-radius: 200px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  outline: none;

  &:hover, &:focus {
    color: white;
    outline: none;
  }
}

.tag_theme {
  background: white;
  text-transform: uppercase;
  color: black;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
}

.btn-default {
  color: white;
  background-color: black;
  border-radius: 200px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 30px 10px 30px;
  outline: none;
  border: none;
  text-decoration: none;

  &:hover, &:focus, &:active {
    background-color: black !important;
    color: white !important;
    outline: none !important;
    text-decoration: none;
  }
}

.btn-white {
  color: black;
  background-color: white;
  border-radius: 50px;
  text-transform: uppercase;
  line-height: 16px;
  outline: none;
  border: none;
  padding: 5px 15px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
}

.center_align {
  text-align: center;
}

.div-action {
  cursor: pointer;
}

.section.experiences {
  h2 {
    text-align: left;
  }

  .line {
    float: left;
    width: 100%;
    margin-top: 30px;
    @media screen and (min-width: 768px) {
      display: flex;
      height: 300px;
      align-items: stretch;
    }

    .experience {
      display: flex;
      align-items: stretch;
      justify-content: center;
      color: white;
      @media(max-width: 767px) {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:hover {
        .inner {
          .bg_img {
            @include transition(all, .5s, ease);
            @include scale(1.2);
          }
        }
      }

      .inner {
        width: 100%;
        float: left;
        position: relative;
        overflow: hidden;

        .bg_img {
          @include transition(all, .5s, ease);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        .text {
          text-align: center;
          padding: 30px 15px;
          width: 100%;
          float: left;
          background-color: rgba(0, 0, 0, 0.5);
          display: grid;
          z-index: 1;
          position: relative;
          height: 100%;

          h5 {
            text-transform: uppercase;
            font-weight: bold;
            //text-overflow: ellipsis;
            overflow: hidden;
            line-height: 20px;
            //white-space: nowrap;
            width: auto;
          }

          span.localization {
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 20px;
            white-space: nowrap;
            width: auto;
            margin-bottom: 10px;
          }

          .icon {
            float: left;
            width: 100%;

            svg {
              height: 50px;

              g {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}

.btn-lg {
  padding: 10px 24px;
}

.btn-long {
  font-size: 12px;
  padding: 10px 24px;
  width: 200px;
}

.btn-very-long {
  font-size: 12px;
  padding: 10px 24px;
  width: 350px;
}

.form-number {
  width: 250px;
}

$burger--thickness: 4px;
.burger.black {
  border-bottom: $burger--thickness solid black;

  &:before {
    border-bottom: $burger--thickness solid black;
  }

  &:after {
    border-bottom: $burger--thickness solid black;
  }
}

.schedule-time {
  select {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.burger {
  width: 28px;
  display: inline-block;
  border: 0;
  border-radius: 2px;
  background: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  border-bottom: $burger--thickness solid white;
  position: fixed;
  z-index: 99999;
  top: 25px;
  right: 15px;
  opacity: 1;
  @include transition(all, .5s, ease);

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    border-bottom: $burger--thickness solid white;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }

  &:after {
    content: "";
    display: block;
    border-bottom: $burger--thickness solid white;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }
}

.burger.active {
  @include transition(all, .5s, ease);
  opacity: 0;
}

.modal-login {
  @media(min-width: 767px) {
    width: 450px !important;
  }
  text-align: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
}

.modal-header {
  border-bottom: 0px solid !important;
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.alignStart {
  display: flex;
  justify-content: flex-start;
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
}

.btCircle {
  background-color: white;
  color: $red;
  border: $red 1px solid;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 50%;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $red;
    outline: none;
    text-decoration: none;
  }
}

.success-message {
  display: flex;
  justify-content: center;
  border-bottom: $grey_9b 1px solid;
  color: $red !important;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 30px;
}

.notification {
  position: relative;
}

.notification[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: 0px;
  right: -40px;
  padding-top: 3px;
  font-size: 16px;
  font-weight: bold;
  background: $red;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 1px #565656;
}

.notification-small {
  position: relative;
}

.notification-small[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: 0px;
  right: -20px;
  padding-top: 1px;
  font-size: 10px;
  font-weight: bold;
  background: $red;
  color: white;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 1px #565656;
}

.small-notification {
  position: relative;
}

.small-notification[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -15px;
  padding-top: 2px;
  padding-left: 3px;
  font-size: 10px;
  font-weight: bold;
  background: $red;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 1px #565656;
}

.redLink {
  color: $red;
  text-decoration: underline;
}

.addLink {
  &:before {
    content: "+";
    color: $red;
    font-weight: 600;
    font-size: 30px;
    float: left;
    border-radius: 25px;
    border: 1px solid $red;
    margin-right: 15px;
    margin-top: -5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  color: $red;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: $red;
    text-decoration: underline;
  }
}

.cancelLink {
  color: $red;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: $red;
    text-decoration: underline;
  }
}

.leftNotification {
  position: relative;
  letter-spacing: 3px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  color: $red;
}

.leftNotification[data-badge]:before {
  content: attr(data-badge);
  position: absolute;
  top: -3px;
  left: 0px;
  padding-top: 3px;
  font-size: 16px;
  font-weight: bold;
  background: $red;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 1px #565656;
}


textarea {
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  padding: 5px;
}

.btn-update {
  color: white;
  background-color: $red;
  border-radius: 200px;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 30px 20px 30px;
  margin-top: 10px;
  outline: none;
  border: none;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: white !important;
    outline: none !important;
    text-decoration: none;
  }

  &.long {
    width: 150px;
  }
}

.checkbox {
  background-color: white;
  border: 1px solid $grey_56;
  width: 20px !important;
  height: 20px;
  margin: 0 !important;
  min-width: 40px !important;
  outline: none !important;
  color: $red;

  &:focus {
    outline: none !important;
    border: 0 solid $grey_56 !important;
  }
}

.title-session {
  font-size: 12px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.btn-popin-login {
  color: white;
}

.margin-top-mobile {
  margin-top: 20px;
  @media(min-width: 767px) {
    margin-top: 0 !important;
  }
}

// ------------------------------------------------

.img-input-file {
  max-width: 100px;
  max-height: 50px;
}

.label-file {
  color: $grey_56;
  background-color: white;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
  outline: none;
  text-decoration: none;
  border-radius: 30px;
  border: 1px solid $grey_56;

  &:hover, &:focus, &:active {
    color: $grey_56 !important;
    outline: none !important;
    text-decoration: none;
  }
}

.input-file {
  display: none !important;
}

.btn-popin-small {
  cursor: pointer;
  width: 160px;
  padding: 10px;
  font-size: 13px;
  color: white;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  border: 0;
  margin-top: 15px;

  &:hover, &:focus {
    text-decoration: none;
    color: white;
    outline: none;
  }
}

.no-padding {
  padding: 0 !important;
}

.btn-disabled {
  background-color: $grey_ccc !important;
  cursor: pointer;
}

.label-checked {
  font-weight: normal;
  font-size: 13px;

  &.enabled {
    color: $black !important;
  }
}

.red-error {
  color: $red;
  font-weight: bold;
}

.green-success {
  color: $green;
  font-weight: bold;
}

// ------------------------------------------------

.hideOnDesktop {
  @media(min-width: 767px) {
    display: none;
  }
}

.hideOnMobile {
  display: none;
  @media(min-width: 767px) {
    display: block;
  }
}

// --------------------------------------------------

.d-flex {
  display: flex !important;
}

.float {
  &-left {
    float: left !important;
  }

  &-right {
    float: right !important;
  }

  &-none {
    float: none !important;
  }
}

.align-items {
  &-center {
    align-items: center !important;
  }

  &-end {
    align-items: end !important;
  }

  &-start {
    align-items: start !important;
  }
}

.justify-content {
  &-center {
    justify-content: center !important;
  }

  &-end {
    justify-content: end !important;
    justify-content: flex-end !important;
  }

  &-start {
    justify-content: start !important;
    justify-content: flex-start !important;
  }

  &-space-around {
    justify-content: space-around !important;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }
}

.text {
  &-uppercase {
    text-transform: uppercase !important;
  }

  &-lowercase {
    text-transform: lowercase !important;
  }

  &-capitalize{
    text-transform: capitalize !important;
  }

  &-align {
    &-right {
      text-align: right !important;
    }

    &-left {
      text-align: left !important;
    }

    &-center {
      text-align: center !important;
    }
  }

  &-underline {
    text-decoration: underline;
  }
}

.position {
  &-relative {
    position: relative !important;
  }

  &-fixed {
    position: fixed !important;
  }

  &-static {
    position: static !important;
  }

  &-absolute {
    position: absolute !important;
  }
}

.w {
  &-100 {
    width: 100% !important;
  }

  &-75 {
    width: 75% !important;
  }

  &-50 {
    width: 50% !important;
  }

  &-25 {
    width: 25% !important;
  }
}

.h {
  &-100 {
    height: 100% !important;
  }

  &-75 {
    height: 75% !important;
  }

  &-50 {
    height: 50% !important;
  }

  &-25 {
    height: 25% !important;
  }
}

.float {
  &-left {
    float: left !important;
  }

  &-right {
    float: right !important;
  }

  &-none {
    float: none !important;
  }
}
