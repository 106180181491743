.footer {
  background: url('../../images/footer.png') center bottom no-repeat transparent;
  background-size: cover;
  text-align: center;
  color: white;

  .btn-lg-footer {
    padding: 15px 50px;
  }

  h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    text-align: center;
    color: white;
  }
  h4 {
    color: $grey_light;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    text-align: center;
  }
  form {
    @media(min-width: 992px) {
      float: left;
      width: 100%;
      margin: 50px 0 20px 0;
    }
    @media(max-width: 767px){
      flex-direction: column;
    }
    width: 75%;
    margin: 0 auto;
    .form-group {
      @media(max-width: 767px) {
        width: 100%;
      }
      width: 45%;
      padding-right: 10px;
      input {
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid white;
        border-radius: 0;
        color: white;
        outline: 0;
        box-shadow: none;
        opacity: 0.4;
        font-size: 19px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3px;
      }
    }
  }
  .message_container {
    text-align: center;
    margin-bottom: 50px;
  }
  .subfooter {
    float: left;
    width: 100%;
    position: relative;
    .social_networks {
      text-align: center;
      display: flex;
      justify-content: center;
      ul {
        margin: 0 auto;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        li {
          float: left;
          padding: 5px;
          &:first-child {
            font-family: $raleway;
            font-size: 18px;
            font-style: italic;
          }
          // a {
          //   background: white;
          //   border-radius: 50px;
          //   height: 25px;
          //   width: 25px;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   color: black;
          //   text-decoration: none;
          // }
          a {
            color: white;
          }
        }
      }
    }
    nav {
      text-align: center;
      display: flex;
      justify-content: center;
      ul {
        margin: 0 auto;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        li {
          float: left;
          padding: 0 10px;
          border-left: 1px solid white;
          &:first-child {
            border: none;
          }
          a {
            @include transition(all, .5s, ease);
            color: white;
            text-decoration: none;
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 3px;
            text-align: left;
            &:hover {
              @include transition(all, .5s, ease);
              color: $red;
            }
          }
        }
      }
    }
    .logo {
      a {
        margin: 0 auto;
        img {
          display: inline-block;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  footer {
    .container-fluid {
      .row {
        .container {
          .col-all {
            form {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              .form-group {
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0;
                input {
                  display: block;
                  font-size: 13px;
                  margin-left: auto;
                  margin-right: auto;
                  padding-left: 25px;
                  padding-right: 25px;
                  text-align: center;
                  width: 100%;
                }
              }
              .btn {
                display: block;
                font-size: 12px;
                margin-left: auto;
                margin-right: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    .container-fluid {
      .row {
        .container {
          .col-all {
            h2 {
              font-size: 14px;
            }
            h4 {
              font-size: 13px;
            }
            form {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              .form-group {
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0;
                input {
                  display: block;
                  font-size: 13px;
                  margin-left: auto;
                  margin-right: auto;
                  padding-left: 25px;
                  padding-right: 25px;
                  text-align: center;
                  width: 100%;
                }
              }
              .btn {
                display: block;
                font-size: 12px;
                margin-left: auto;
                margin-right: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  footer {
    .container-fluid {
      .row {
        .container {
          .col-all {
            h2 {
              font-size: 14px;
            }
            h4 {
              font-size: 13px;
            }
            .form-inline {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              .form-group {
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0;
                input {
                  display: block;
                  font-size: 13px;
                  margin-left: auto;
                  margin-right: auto;
                  padding-left: 25px;
                  padding-right: 25px;
                  text-align: center;
                  width: 100%;
                }
              }
              .btn {
                display: block;
                font-size: 12px;
                margin-left: auto;
                margin-right: auto;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}