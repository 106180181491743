$black: #111;
$webpath: '../../../../public/';
$raleway: 'Raleway', sans-serif;
$playfair: 'Playfair Display';
$playfairBold: 'playfair_displaybold';
$fontAwesome: 'FontAwesome';
$grey_56: #565656;
$grey_9b: #9b9b9b;
$red: #c82847;
$grey_light: #f5f5f5;
$grey_ccc:#ccc;
$grey_b2: #b2b2b2;
$orange:#cf5316;
$pink:#c82847;
$green : green;
$blue : blue;
$beige : beige;
$grey_avatar: #565656;

@mixin transition($element,$speed,$action) {
  -webkit-transition: $element $speed $action;
  -moz-transition: $element $speed $action;
  -o-transition: $element $speed $action;
  transition: $element $speed $action;
}

@mixin scale($value) {
  -ms-transform: scale($value);
  -moz-transform: scale($value);
  -webkit-transform: scale($value);
  -o-transform: scale($value);
  transform: scale($value);
}

/* colors themes */
$gastronomie: #f8f3d3;
