.home {
  overflow-x: hidden;
  padding: 0;

  .cgu {
    overflow-y: scroll;
    height: 300px;
    border: $grey_9b 1px solid;
    padding: 10px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .section {
    height: auto;
    z-index: 0;
    .carousel {
      .inner {
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: baseline;
          justify-content: center;
        }
        text-align: center;
        color: $grey_56;
        .theme {
          color: $grey_56;
          text-align: center;
          text-decoration: none;
          cursor: pointer;
          flex: 1;
          justify-content: center;
          display: grid;
          svg {
            g {
              fill: $grey_56;
            }
          }
          h4 {
            text-transform: uppercase;
            font-family: $playfair;
          }
          p {
            color: $grey_9b;
          }
        }
        .friend {
          @media(min-width: 768px) and (max-width: 1199px) {
            max-width: 33%;
          }
          @media screen and (min-width: 768px) {
            flex: 1;
            max-width: 25%;
          }
          text-align: center;
          .vignette {
            cursor: pointer;
            position: relative;
            border-radius: 200px;
            padding: 15px;
            width: 164px;
            height: 164px;
            @media screen and (max-width: 767px) {
              width: 100px;
              height: 100px;
            }
            display: flex;
            align-items: center;
            margin: 0 auto 15px auto;
            background-position: center center;
            background-repeat: repeat;
            .avatar {
              border-radius: 200px;
              height: 100%;
              width: 100%;
              background-position: center center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
          .description {
            position: relative;
            float: left;
            width: 100%;
            span {
              float: left;
              width: 100%;
              text-transform: capitalize;
            }
            .name {
              font-size: 18px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.2px;
              text-align: center;
              color: $grey_56;
            }
            .city {
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.2px;
              text-align: center;
              color: $grey_56;
            }
          }
          .actions {
            margin-top: 15px;
            float: left;
            width: 100%;
            position: relative;
            .section {
              padding: 15px 0;
              .tag_theme {
                @media screen and (max-width: 767px) {
                  font-size: 10px;
                }
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 3.6px;
                padding-top: 10px;
                text-align: center;
                color: black;
              }
              .btn {
                padding: 5px 30px 5px 30px;
                font-size: 14px;
                @media screen and (max-width: 767px) {
                  padding: 5px 20px;
                  font-size: 12px;
                }
                font-weight: 400;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 3px;
                text-align: center;
                color: white;
              }
            }
            .section.themes {
              display: grid;
              span {
                max-width: 100%;
                margin: 0 auto 5px auto;
              }
            }
          }
        }
      }
    }

  }
  .carousel-control {
    color: $grey_56;
    opacity: 1;
    background: none;
    text-shadow: none;
    width: 50px;
    .fa-angle-right {
      margin-right: -10px;
      width: 50px;
      height: 50px;
      margin-top: -10px;
      font-size: 50px;
      right: 0%;
    }
    .fa-angle-left {
      margin-left: -10px;
      width: 50px;
      height: 50px;
      margin-top: -10px;
      font-size: 50px;
      left: 0%;
    }
    .fa-angle-right, .fa-angle-left {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      z-index: 5;
      display: inline-block;
    }
  }
  .section.friends {
    background-color: $grey_light;
    .btn-default.big {
      margin-top: 20px;
    }
  }

  .section.locations {
    @media(max-width: 767px) {
      padding: 30px 0;
    }
    padding: 30px 0 90px 0;
    .inner {
      @media(min-width: 768px) {
        display: flex;
      }
      align-items: center;
      justify-content: center;
      text-align: center;
      .line {
        float: left;
        display: flex;
        justify-content: space-between;
      }
      .location {
        @media(min-width: 1200px) {
          height: 200px;
        }
        @media(min-width: 768px) {
          margin: 10px;
        }
        @media(max-width: 767px) {
          margin-bottom: 20px;

        }
        height: 140px;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: white;
        position: relative;
        .bg_img {
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          @include transition(all, .5s, ease);
        }
        h4 {
          @media (min-width: 1200px) {
            font-size: 20px;
          }
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          text-transform: lowercase;
          text-align: center;
          color: white;
          text-shadow: 0 1px 13px rgba(0, 0, 0, 0.77);
          z-index: 1;
        }
        &:hover {
          .bg_img {
            @include transition(all, .5s, ease);
            @include scale(1.2);
          }
        }
        h4:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .section.stories {
    .title_bg {
      //font-size: 288px;
      position: absolute;
      //top: -50px;
      left: 50%;
      transform: translateX(-50%);
      opacity: .09;
      font-weight: 200;
      text-transform: capitalize;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 3.3px;
      z-index: -1;
      width: auto;
      color: #91aeec;
      @media screen and (max-width: 767px) {
        font-size: 10rem;
        top: -1rem;
      }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 20rem;
        top: -7rem;
      }
      @media screen and (min-width: 1200px) {
        font-size: 30rem;
        top: -15rem;
      }
    }
    .list_stories {
      float: left;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 40px;
      .col-25 {
        width: 25%;
        float: left;
        position: relative;
      }
      .col-50 {
        width: 50%;
        float: left;
        position: relative;
      }
      .grid-item {
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 0;
        overflow: hidden;
        float: left;
        .inner {
          position: relative;
          width: 100%;
          height: 100%;
          .bg_img {
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-position: center center;
            @include transition(all, .5s, ease);
          }
          .text {
            z-index: 1;
            .tag_theme {
              margin-left: 10px;
              margin-right: 10px;
              @media screen and (max-width: 767px) {
                font-size: 10px;
              }
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 3.6px;
              text-align: center;
              position: absolute;
              z-index: 1;
              top: 20px;
              right: 20px;
            }
          }
        }
        &:hover {
          .bg_img {
            @include transition(all, .5s, ease);
            @include scale(1.2);
          }
        }
      }
      .grid-item.small {
        width: 100%;
        @media(max-width: 767px) {
          margin-bottom: 15px;
        }
        position: relative;
        //float: left;
        @media(min-width: 1200px) {
          height: 225px;
        }
        height: 150px;
        text-align: center;
        .title {
          color: white;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
          position: absolute;
          bottom: 20px;
          width: 100%;
          left: 0;
          padding: 0 15px;
          font-weight: bold;
        }
      }
      .grid-item.big {
        @media(min-width: 1200px) {
          height: 450px;
        }
        height: 300px;
        width: 100%;
        position: relative;
        //float: left;
        .description {
          background-color: rgba(255, 255, 255, .8);
          color: black;
          min-height: 50%;
          max-height: 50%;
          float: left;
          width: 100%;
          padding: 15px;
          position: absolute;
          bottom: 0;
          left: 0;
          .title {
            font-weight: bold;
          }
          .date {
            font-size: 12px;
            margin-bottom: 15px;
            float: left;
            width: 100%;
          }
        }
      }
      .grid-item.very_big {
        @media(min-width: 1200px) {
          height: 450px;
        }
        height: 300px;
        width: 100%;
        position: relative;
        //float: left;
        .description {
          background-color: rgba(255, 255, 255, .8);
          color: black;
          float: left;
          padding: 15px;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 0;
          display: flex;
          align-items: center;
          height: 100%;
          .inner_description {
            position: relative;
            .title {
              font-weight: bold;
            }
            .date {
              font-size: 12px;
              margin-bottom: 15px;
              float: left;
              width: 100%;
            }
          }
        }
      }
    }
  }

  #tarteaucitronAlertBig{
    top: 95vh;
    height: 5vh;
  }
}
