#datepicker{
  float: left;
  width: 100%;
  .datepicker {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 300px;
    table {
      width: 100%;
      .datepicker-switch {
        background-color: white;
        color: $pink;
        &:hover {
          color: $pink;
        }
      }
      .prev, .next {
        background-color: white;
        span {
          background-color: $grey_light;
          border-radius: 200px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      }
      tbody {
        tr {
          td.day.disabled {
            color: inherit;
            background: repeating-linear-gradient(-55deg, $grey_light, $grey_light 2px, white 4px, white 4px);
            &:hover {
              background: repeating-linear-gradient(-55deg, $grey_light, $grey_light 2px, white 4px, white 4px);
              color: inherit;
            }
          }
          td.day {
            background-color: $pink;
            color: white;
            @include transition(all,.5s,ease);
            &:hover {
              background-color: black;
              color: white;
              @include transition(all,.5s,ease);
            }
          }
          td.day.active {
            background-color: black;
            color: white;
            background-image: none;
            &:hover {
              color: white;
            }
          }
          td.day.focused {
            background-color: black;
            color: white;
          }
          td{
            border-radius: 200px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}

#datepickerAmbassador{
  float: left;
  width: 100%;
  .datepicker {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 300px;
    table {
      width: 100%;
      .datepicker-switch {
        background-color: white;
        color: $pink;
        &:hover {
          color: $pink;
        }
      }
      .prev, .next {
        background-color: white;
        span {
          background-color: $grey_light;
          border-radius: 200px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      }
      tbody {
        tr {
          td.day.disabled {
            color: inherit;
            background: repeating-linear-gradient(-55deg, $grey_light, $grey_light 2px, white 4px, white 4px);
            &:hover {
              background: repeating-linear-gradient(-55deg, $grey_light, $grey_light 2px, white 4px, white 4px);
              color: inherit;
            }
          }
          td.day {
            background-color: #c828473d;
            color: white;
            @include transition(all,.5s,ease);
            &:hover {
              background-color: rgba(188, 41, 71, 0.24);
              color: white;
              @include transition(all,.5s,ease);
            }
          }
          td.day.active {
            background-color: $pink;
            color: white;
            background-image: none;
            &:hover {
              color: white;
            }
          }
          td.day.focused {
            background-color:  rgba(188, 41, 71, 0.24);
            color: white;
          }
          td{
            border-radius: 200px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
