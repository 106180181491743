.ambassadorPopin {
  .logo {
    width: 110px;
    height: 110px;
  }
  .titleFake {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .title {
    text-align: center;
    color: $pink;
    margin: 10px;
    font-size: 25px;
    font-weight: bold;
  }
  .subtitle {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 30px;
    text-align: left;
    color: $grey_56;
    font-size: 14px;
    &.center {
      text-align: center !important;
    }
  }
  .subscribe {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: $grey_56;
    font-size: 12px;
  }
  .condition {
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
    label {
      margin-left: 10px;
    }
  }
  .mainStephHome {
    margin-top: 20px;
    padding: 20px;
  }
  .btn-popin {
    cursor: pointer;
    padding: 10px 30px 10px 30px;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    outline: none;
    text-decoration: none;
    border: none;
    &:hover, &:focus {
      text-decoration: none;
      color: white;
      outline: none !important;
    }
  }
  .pink {
    color: $red;
  }
  .advise{
    text-align: left;
    color:$grey_9b;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .kosvg {
    padding-left: 20px;
    margin-right: 10px;
  }
  .light-strength{
    color: $pink;
  }
  .middle-strength{
    color: $orange;
  }
  .strong-strength{
    color: green;
  }

  .btn-submit-ambassador {
    width: 300px;
    margin: 20px auto 30px auto;
  }

  .form-group {
    margin-bottom: 25px;
    .eighty {
      width: 80%;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }

  input {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    min-width: 300px;
  }
  .form-control {
    @include transition(all, .5s, ease);
  }
  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: transparent;
    opacity: .2;
    @include transition(all, .5s, ease);
  }
}
