.story {
  .streamer {
    h2 {
      @media (max-width: 767px) {
        font-size: 30px;
      }
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.63;
      letter-spacing: 0.5px;
      text-align: center;
      color: #000000;
    }
    h1 {
      @media (max-width: 767px) {
        font-size: 28px;
      }
      font-size: 36px;
      font-weight: 500;
      font-style: italic;
      font-stretch: normal;
      line-height: 0.97;
      letter-spacing: 0.4px;
      text-align: center;
      color: black;
    }
  }

  .text {
    font-size: 20px;
    @media screen and (max-width: 767px){
      font-size: 14px;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: $grey_56;
    h1 {
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #000000;
    }
    h2 {
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      text-align: center;
      color: black;
      font-size: 30px;
      letter-spacing: 0.3px;
      margin-bottom: 40px;
    }
  }
}
