/* raleway-200 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/raleway/raleway-v12-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
  url('../../fonts/raleway/raleway-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/raleway/raleway-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-200.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/raleway/raleway-v12-latin-200.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-200italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  src: url('../../fonts/raleway/raleway-v12-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
  url('../../fonts/raleway/raleway-v12-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/raleway/raleway-v12-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-200italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/raleway/raleway-v12-latin-200italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/raleway/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
  url('../../fonts/raleway/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/raleway/raleway-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/raleway/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/raleway/raleway-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Italic'), local('Raleway-Italic'),
  url('../../fonts/raleway/raleway-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/raleway/raleway-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/raleway/raleway-v12-latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/raleway/raleway-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
  url('../../fonts/raleway/raleway-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/raleway/raleway-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-800.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/raleway/raleway-v12-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: url('../../fonts/raleway/raleway-v12-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
  url('../../fonts/raleway/raleway-v12-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/raleway/raleway-v12-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/raleway/raleway-v12-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/raleway/raleway-v12-latin-800italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/playfair/playfair-display-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
  url('../../fonts/playfair/playfair-display-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/playfair/playfair-display-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/playfair/playfair-display-v13-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/playfair/playfair-display-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'),
  url('../../fonts/playfair/playfair-display-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/playfair/playfair-display-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/playfair/playfair-display-v13-latin-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-900 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/playfair/playfair-display-v13-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Black'), local('PlayfairDisplay-Black'),
  url('../../fonts/playfair/playfair-display-v13-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/playfair/playfair-display-v13-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/playfair/playfair-display-v13-latin-900.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-900italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 900;
  src: url('../../fonts/playfair/playfair-display-v13-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'),
  url('../../fonts/playfair/playfair-display-v13-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/playfair/playfair-display-v13-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/playfair/playfair-display-v13-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/playfair/playfair-display-v13-latin-900italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-bold */
@font-face {
  font-family: 'playfair_displaybold';
  src: url('../../fonts/playfair/playfairdisplay-bold-webfont.woff2') format('woff2'),
  url('../../fonts/playfair/playfairdisplay-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}