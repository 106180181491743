.reservation {
  .success-message {
    display: flex;
    justify-content: center;
    border-bottom: $grey_9b 1px solid;
    color: $red !important;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .layTitle {
    position:relative;
    display: flex;
    justify-content: center;
    width: 100%;
    .btn-title {
      margin-top: -15px;
      color: white;
      font-size: 12px;
      letter-spacing: 3px;
      font-weight: 500;
      background-color: $black;
      border-radius: 200px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-align: center;
      text-transform: uppercase;
      outline: none;
      cursor: auto !important;
    }
  }
  .btn-experience {
    padding: 5px 30px 5px 30px;
    @media screen and (max-width: 767px) {
      padding: 5px 20px;
    }
    color: white;
    font-size: 12px;
    letter-spacing: 3px;
    font-weight: 500;
    background-color: $black;
    border-radius: 200px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    outline: none;
    margin-top: 10px;
    cursor: auto !important;
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: white !important;
      outline: none !important;
      text-decoration: none;
    }
  }
  header {
    .streamerProfile {
      float: left;
      width: 100%;
      position: relative;
      text-align: center;
      color: black;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: center;
      h1 {
        text-transform: capitalize;
        font-weight: normal;
        font-size: 30px;
      }
      h2 {
        font-style: italic;
        font-weight: bold;
      }
    }
    .top_header {
      background-size: cover;
      .headline {
        color: white;
        &.black {
          color: black;
        }
        a {
          background: url('../../images/logo_white.svg') center center transparent no-repeat scroll;
        }
      }

      nav {
        ul {
          li {
            &:before {
              content: '|';
              color: white;
            }
            a {
              color: white;
              &.black {
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .message-profile {
    padding-bottom: 20px;
  }
  .btn-update {
    color: white;
    background-color: $red;
    border-radius: 200px;
    font-size: 10px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 30px 15px 30px;
    margin-top: 10px;
    outline: none;
    border: none;
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: white !important;
      outline: none !important;
      text-decoration: none;
    }
  }

  .message {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .nopadding {
    padding: 0 !important;
    align-content: flex-start;
  }
  .padding-left {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .vignette {
    cursor: auto !important;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 200px;
    width: 135px;
    height: 135px;
    align-items: center;
    &.nocenter {
      margin: 0 !important;
    }
    &.medium {
      width: 105px !important;
      height: 105px !important;
    }
    &.small {
      width: 50px !important;
      height: 50px !important;
    }
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: repeat;
    background-color: $grey-9b;
    .avatar {
      border-radius: 200px;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .idcardFile {
    position: relative;
    border-radius: 200px;
    width: 95px;
    height: 60px;
    margin: 0;
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: repeat;
    background-color: $grey-56;
    .avatar {
      border-radius: 10px;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .oksvg {
    padding-left: 10px;
    width: 35px;
    height: 35px;
  }
  .idcard {
    position: relative;
    border-radius: 200px;
    width: 65px;
    height: 60px;
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: repeat;
    .avatar {
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .errorPassword {
    color: $red;
    font-weight: bold;
  }
  .infos {
    color: $grey_56;
    .tab-title {
      font-size: 3rem;
    }
    .completion {
      color: $red;
      font-size: 36px;
    }
  }
  .deleteLay {
    border-top: 1px solid $grey_ccc;
    max-width: 470px;
    padding-top: 10px;
    margin-top: 30px;
  }
  .left-menu {
    h1 {
      font-size: 25px;
      color: $grey_56;
      font-weight: bold;
    }
    .breadcrumbLeft {
      text-decoration: none;
      text-transform: uppercase;
      color: $grey_b2;
      font-size: 12px;
      &.active {
        font-weight: bold;
        color: $red;
      }
    }
    .separator {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 18px;
    }
  }
  .redTitle{
    color: $red;
    font-size: 30px;
  }
  .formPassword {
    padding-top: 20px;
    input {
      max-width: 370px;
    }
  }
  input {
    padding: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }
  .small-text {
    font-size: 12px;
    font-weight: normal;
    color:$grey_56;
    &.red{
      color: $red !important;
    }
  }
  .layAdding{
    width: 500px;
    padding: 0px;
  }
  .layCustomer {
    &.layPartner {
      background-color: $grey_light;
      margin-bottom: 20px;
    }
    padding: 20px;
  }

  .btDelete {
    background-color: white;
    color: $red;
    border: $red 1px solid;
    text-decoration: none;
    border-radius: 100%;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    &:hover, &:focus, &:active {
      outline: none;
      text-decoration: none;
    }
  }

  .addingNext{
    border-top: $grey_9b 1px solid;
    margin-top: 30px;
  }
  .experience {
    background-color: $beige;
    padding: 30px;
    .name {
      font-size: 18px;
      color: $black;
      font-weight: bold;
    }
    .title {
      color: $red;
      font-size: 18px;
      font-weight: bold;
      &.border {
        border-top: $grey_9b 1px solid;
        padding-top: 10px;
        margin-top: 10px;
      }
    }
  }
  .experience-next {
    margin-top: 20px;
    color: $grey_9b;

    .condition {
      margin-top: 20px;
      padding-top: 10px;
      text-align: center;
      border-top: $grey_9b 1px solid;
      a {
        font-size: 14px;
        color: $grey_ccc;
      }
    }
    .schedule {
      border-top: $grey_9b 1px solid;
      border-bottom: $grey_9b 1px solid;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
    }
  }
}
