.recruitment {
  padding: 0;
  font-size: 18px;
  .btn-default {
    font-size: 18px;
  }
  .orange {
    background-color: $orange;
    color: white;
    @include transition(all, .5s, ease);
    &:hover, &:focus {
      @include transition(all, .5s, ease);
      background-color: white !important;
      color: $orange !important;
    }
  }
  .orange.border {
    border: 1px solid $orange;
  }
  .white {
    background-color: white;
    color: black !important;
    @include transition(all, .5s, ease);
    &:hover {
      @include transition(all, .5s, ease);
      color: white !important;
    }
  }
  .border_orange_full {
    background-color: $orange;
    color: white;
    border: 1px solid $orange;
    @include transition(all, .5s, ease);
    &:hover, &:focus {
      @include transition(all, .5s, ease);
      background-color: transparent !important;
      color: $orange !important;
    }
  }
  .border_orange {
    background-color: transparent;
    color: $orange;
    border: 1px solid $orange;
    @include transition(all, .5s, ease);
    &:hover, &:focus {
      @include transition(all, .5s, ease);
      background-color: $orange !important;
      color: white !important;
    }
  }
  header {
    position: relative;
    a.angle {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 44px;
      color: white;
      text-decoration: none;
      @include transition(all, .5s, ease);
      &:hover {
        @include transition(all, .5s, ease);
        color: $orange;
      }
    }
    .top_header {
      box-shadow: none;
      position: absolute;
      z-index: 2;
      padding-top: 10px;
      .row {
        nav {
          ul {
            display: flex;
            align-items: center;
            li {
              color: white;
              &:before {
                display: none;
              }
              a {
                color: white;
                font-weight: normal;
                text-transform: none;
                font-size: 18px;
              }
              a.btn-default {
                border-radius: 50px;
                padding: 10px 16px;
                border: 1px solid white;
                background: transparent;
                @include transition(all, .5s, ease);
                &:hover {
                  @include transition(all, .5s, ease);
                  color: black !important;
                  background: white !important;
                }
              }
            }
          }
        }
        .headline {
          color: white;
          &.black {
            color: black;
          }
          a {
            color: white;
            background: url('../../images/logo_white.svg') center center no-repeat scroll;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
    .slide {
      z-index: 0;
      height: 100vh;
      .carousel-inner {
        height: 100%;
        .item {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
        }
      }
    }
    .slider_text {
      font-size: 18px;
      z-index: 1;
      position: absolute;
      height: 100vh;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      .text {
        float: left;
        width: 100%;
        color: white;
        h1 {
          font-size: 48px;
          color: white;
          font-family: $playfair;
          font-style: italic;
          margin-bottom: 20px;
        }
        p {
          opacity: .6;
        }
        hr {
          background-color: white;
          width: 75%;
          float: left;
          opacity: .6;
        }
      }
      .video {
        float: left;
        width: 100%;
        a {
          color: white;
          display: flex;
          align-items: center;
          text-decoration: none;
          i {
            margin-right: 20px;
            font-size: 44px;
          }
        }
      }
      .links {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10vh;
        font-size: 18px;

        a.orange {
          width: 250px;
          margin-right: 15px;
        }
        a.white {
          width: 250px;
          margin-left: 15px;
        }
      }
    }
  }
  .concept {
    .text {
      float: left;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey_ccc;
      h1 {
        font-family: $playfairBold;
        text-transform: capitalize;
        font-size: 48px;
        color: black;
        margin-bottom: 40px;
      }
      p {
        color: $grey_56;
      }
    }
    .links {
      float: left;
      width: 100%;
      padding: 20px 0;
      align-items: center;
      display: flex;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          float: left;
          margin-left: 10px;
          &:first-child {
            margin: 0;
          }
          a {
            background-color: $grey_b2;
            padding: 5px;
            color: white;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            float: left;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            @include transition(all, .5s, ease);
            &:hover {
              @include transition(all, .5s, ease);
              background-color: $orange;
            }
          }
        }
      }
    }
  }
  .friends {
    .slide {
      z-index: 0;
      width: 100%;
      .carousel-inner {
        height: 80vh;
        .item {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
        }
      }
      .carousel-control {
        opacity: .5;
        @include transition(all, .5, ease);
        &:hover {
          @include transition(all, .5, ease);
          opacity: 1;
        }
        span {
          position: absolute;
          top: 50%;
          margin-top: -10px;
          z-index: 5;
          transform: translateX(-50%);
          border: 2px solid;
          border-radius: 50px;
          align-items: center;
          display: flex;
          justify-content: center;
          width: 70px;
          height: 70px;
          text-shadow: none;
        }
      }
      .left.carousel-control {
        left: 0;
        width: 10%;
        span {
          left: 50%;
        }
      }
      .right.carousel-control {
        right: 0;
        width: 10%;
      }
    }
    .text {
      background-color: white;
      padding: 60px;
      z-index: 1;
      width: 100%;
      position: relative;
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 15%;
        width: 45vw;
        right: 15%;
      }
      @media screen and (min-width: 1920px) {
        width: 35vw;
      }
      h1 {
        font-family: $playfairBold;
        font-size: 48px;
        color: black;
        margin-top: 0;
        margin-bottom: 40px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  .joinus {
    background: url('../../images/recruitment/joinus.png') 0 0 scroll transparent no-repeat;
    background-size: cover;
    @media screen and (min-width: 1920px) {
      padding-top: 350px;
    }
    padding-top: 600px;
    .blocForm {
      float: left;
      width: 100%;
      background-color: #faf6ed;
      padding: 80px;
      .intro {
        text-align: center;
        font-size: 24px;
        color: #666;
        h1 {
          color: $orange;
          font-size: 48px;
          margin: 0 0 10px 0;
          font-family: $playfairBold;
        }
      }
      #thanks {
        .icon {
          color: $orange;
          font-size: 48px;
          margin: 0;
        }
        .message {
          margin: 40px 0;
          float: left;
          width: 100%;
          a {
            color: $orange;
            text-decoration: none;
          }
        }
        h3 {
          margin-top: 0;
        }
      }
      form {
        h3 {
          border-bottom: 1px solid $grey_56;
          color: $grey_56;
          float: left;
          width: 100%;
          padding-bottom: 20px;
          margin-bottom: 50px;
          span {
            color: $orange;
          }
        }
        .message {
          float: left;
          width: 100%;
        }
        .form-control {
          &:focus {
            border-color: $orange;
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
        .form-group {
          width: 100%;
          float: left;
          margin-bottom: 30px;
          .br-theme-fontawesome-stars-o {
            .br-widget {
              float: left;
              display: flex;
              align-items: center;
              a {
                font-size: 30px;
                margin-right: 10px;
                &:after {
                  color: $orange;
                }
              }
              a.br-selected {
                &:after {
                  color: $orange;
                }
              }
              .br-current-rating {
                display: block;
                float: right;
              }
            }
          }
          label {
            font-weight: normal;
          }
          input, select {
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
            border-radius: 0;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            &:focus {
              border-bottom: 1px solid $orange;
            }
          }
          .radio + .radio, .checkbox + .checkbox {
            margin-top: 0;
          }
          input[type="radio"],
          input[type="checkbox"] {
            margin-left: 10px !important;
            opacity: 0;
            display: block;
          }
        }
        .form-group {
          label.control-label {
            font-size: 24px;
          }
          .input-group {
            input[type='text'] {
              background-color: white;
              border: 1px solid $grey_ccc;
            }
          }
        }
        .title {
          label.control-label {
            &:after {
              content: ':';
              float: right;
              margin-left: 5px;
            }
          }
        }
        .title.removeAfter {
          label.control-label {
            &:after {
              display: none;
            }
          }
        }
        .flex {
          display: flex;
          align-items: center;
        }
        .form-group.radios {
          .radio {
            margin-top: 0;
            min-width: 225px;
            float: left;
            label {
              padding-left: 0;
              line-height: 30px;
              width: 100%;
              text-indent: 40px;
            }
            .label-radio {
              &:before {
                background-color: white;
              }
              &:after {
                background-color: $orange;
              }
            }
          }
          .checkbox {
            border: none;
            min-width: 30px !important;
            height: 39px;
            background: none;
            margin: 0 !important;
            label {
              padding-left: 0;
              text-indent: 50px;
              line-height: 30px;
              width: 100%;
              color: #333333;
            }
            .label-radio {
              &:before {
                background-color: white;
                width: 30px;
                height: 30px;
              }
              &:after {
                background-color: $orange;
                left: 15px;
              }
            }
          }
          .inline.flex {
            .radio {
              margin-top: 0;
              width: 100%;
              float: left;
              label {
                padding-left: 0;
                text-indent: 50px;
                line-height: 30px;
                width: 100%;
              }
              .label-radio {
                &:before {
                  background-color: white;
                  width: 30px;
                  height: 30px;
                }
                &:after {
                  background-color: $orange;
                  left: 15px;
                }
              }
            }
          }
        }
        .cg_number, .siret, .priceJourney, .types, .publicationPrice {
          display: none;
          .flex {
            display: flex;
            align-items: center;
          }
        }
        .actions {
          margin-top: 40px;
          float: left;
          width: 100%;
          .right {
            float: right;
          }
          .left {
            float: left;
          }
        }
      }
    }
  }
  footer {
    background: $orange;
    .subfooter {
      .social_networks {
        ul {
          li {
            a {
              color: $orange;
            }
          }
        }
      }
      nav {
        ul {
          li {
            a {
              &:hover {
                color: white;
              }
            }
          }
        }
      }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

#legalNoticesModal {
  font-size: 14px;
  h3{
    font-weight: bold;
  }
}
