.ambassador_profile {
  header {
    .streamerProfile {
      float: left;
      width: 100%;
      position: relative;
      text-align: center;
      color: black;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: center;
      h1 {
        text-transform: capitalize;
        font-weight: normal;
        font-size: 30px;
      }
      h2 {
        font-style: italic;
        font-weight: bold;
      }
    }
    .top_header {
      background-size: cover;
      .headline {
        color: white;
        &.black {
          color: black;
        }
        a {
          background: url('../../images/logo_white.svg') center center transparent no-repeat scroll;
        }
      }
      nav {
        ul {
          li {
            &:before {
              content: '|';
              color: white;
            }
            a {
              color: white;
              &.black {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  .left-menu {
    a {
      text-decoration: none;
      color: $grey_56;
      &.active {
        font-weight: bold;
      }
      font-size: 26px;

      &:hover, &:focus, &:active {
        outline: none;
        text-decoration: none;
      }
    }
    .subMenu {
      padding-bottom: 20px;
      div{
        margin-top: 15px;
      }
      a {
        font-size: 18px;
        &.active {
          font-weight: normal;
          color: $red;
        }
      }
    }
  }
  .btn-update {
    color: white;
    background-color: $red;
    border-radius: 200px;
    width: 180px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 30px 10px 30px;
    margin-top: 15px;
    outline: none;
    border: none;
    text-decoration: none;
    a{
      color: white;
      &:hover, &:focus, &:active {
        color: white !important;
        outline: none !important;
        text-decoration: none;
      }
    }

  }
  .deleteLay{
    border-top: 1px solid $grey_ccc;
    padding-top: 10px !important;
    padding-left: 0px !important;
    margin-top: 30px;
    a {
      font-size: 14px;
      text-decoration: underline;
      color: $red;
    }
  }
  .showLay{
    padding-left: 0px !important;
    a {
      font-size: 14px;
      text-decoration: underline;
      color: $red;
    }
  }

  .red-title {
    color: $red;
  }
  .infos {
    margin-left: 0px !important;
    color: $grey_56;
    font-size: 18px !important;

    .bold{
      font-weight: bold;
    }
    .layCancel{
      border-top: $grey_9b 1px solid;
      padding-top: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .grey-background {
      background-color: $grey_light;
    }
    .beige-background {
      background-color: $beige;
    }
    .layPrice{
      border-top: $grey_9b 1px solid;
      border-bottom: $grey_9b 1px solid;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .tab-title {
      letter-spacing: 1px;
      font-size: 2rem;
      @media(min-width: 767px) {
        font-size: 3rem;
      }
    }
    a {
      &.red-title {
        color: $red;
        text-decoration: underline;
        font-size: 14px;
      }
    }
    .tab-subtitle {
      color: $grey_56;
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .completion{
      color: $red;
      font-size: 36px;
    }
    .title-experience{
      font-size: 22px;
      color: $black;
      font-weight: bold;
    }
    .listed_experiences {
      margin: 40px auto 40px auto;
      .experience {
        padding-bottom: 40px;
        padding-right: 30px;
        display: flex;
        align-items: stretch;
        justify-content: center;
        color: white;
        width: 360px;
        height: 360px;
        &:hover {
          .inner {
            .bg_img {
              @include transition(all, .5s, ease);
              @include scale(1.2);
            }
          }
        }
        .inner {
          width: 100%;
          float: left;
          position: relative;
          overflow: hidden;

          .btn-white {
            color: black;
            background-color: white;
            border-radius: 200px;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 3px;
            text-align: center;
            text-transform: uppercase;
            padding: 10px 30px 10px 30px;
            outline: none;
            border: none;
            text-decoration: none;
          }

          .bg_img {
            @include transition(all, .5s, ease);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
          .text {
            text-align: center;
            padding: 30px 15px;
            width: 100%;
            float: left;
            background-color: rgba(0, 0, 0, 0.5);
            display: grid;
            align-items: center;
            z-index: 1;
            position: relative;
            height: 100%;
            h5 {
              font-size: 25px;
              @media(max-width: 1199px){
                font-size: 20px;
              }
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              text-align: center;
              color: white;
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              //white-space: nowrap;
              width: auto;
            }
            span.localization {
              font-size: 15px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 2.3px;
              color: #ffffff;
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 20px;
              white-space: nowrap;
              width: auto;
              margin-bottom: 10px;
            }
            .icon {
              float: left;
              width: 100%;
              svg {
                height: 50px;
                g {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }
  .vignette {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 200px;
    width: 135px;
    height: 135px;
    &.nocenter {
      margin: 0 !important;
    }
    &.small {
      width: 50px !important;
      height: 50px !important;
    }
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: repeat;
    background-color: $grey_avatar;
    .avatar {
      border-radius: 200px;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .success-message {
    display: flex;
    justify-content: center;
    border-bottom: $grey_9b 1px solid;
    color: $red !important;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 30px;
    text-decoration: underline;
  }
  h2 {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    padding-top: 20px;
  }
  .col-centered{
    margin-top: 20px;
  }
  .iconTheme{
    svg{
      width: 40px !important;
    }
  }
  ul.list_infos {
    list-style: none;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
      .libelle {
        font-weight: bold;
        margin-right: 5px;
      }
      .icon {
        color: $red;
        width: 40px;
        font-size: 24px;
        svg {
          g {
            color: $red;
          }
        }
      }
    }
  }
  .formSchedule {
    padding-left: 20px;
    padding-bottom: 10px;
    &.small{
      input {
        width: 50px;
      }
    }
  }
  .redSentence{
    color: $red;
  }
  .boldSentence{
    font-weight: bold;
    &.languages{
      ul{
        padding: 0;
        margin-top: 15px;
        list-style: none;
      }
    }
  }
  .reservation-form {
    font-size: 12px;
    color: $red;
    input{
      margin: 10px;
    }
    label{
      font-weight: normal !important;
    }
  }
  .layResaValidate{
    margin-top: 10px;
    border-top: $grey_9b 1px solid;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  .nomargin{
    margin: 0 !important;
  }
  .form-group {
    label.control-label {
      font-weight: normal;
    }
    input {
      font-weight: bold;
      padding-left: 0px;
      background-color: white;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $grey_56 !important;
      border-radius: 0;
      max-width: 400px;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      &:focus {
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
  .no-padding{
    padding: 0 !important;
  }
  .padding-top{
    padding-left: 0 !important;
    padding-top: 5px !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
  .form-control {
    font-size: 16px;
  }
  .flex-container {
    background-color: transparent !important;
    resize: horizontal;
    overflow: hidden;
    display: flex;
  }

  .item {
    margin: 1em;
    padding: 0.5em;
    width: 150px;
    min-width: 0;
  }
  .initial {
    flex: initial;
  }
  .auto {
    flex: auto;
  }
  .color-background {
    background-color: $grey_light;
  }
  .languages{
    label{
      margin-bottom: 1rem;
    }
    select{
      height: 300px;
    }
  }
  .ages{
    input{
      margin: 0;
    }
    label{
      margin: 0 0 0 1rem;
    }
  }
}
