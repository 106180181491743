.loginPopin {
  .modal-header {
    padding: 10px 15px 5px 0;
  }
  .connectLink {
    margin-left: 5px;
    color: $pink;
    font-weight: bold;
  }
  .title {
    text-align: center;
    color: $grey_56;
    font-size: 15px;
    font-weight: bold;
  }
  .subscribe {
    border-top: $grey_ccc 1px solid;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    color: $grey_56;
    font-size: 14px;
  }

  .centered-legend {
    border-top: 1px solid $grey_ccc;
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .legend {
      display: inline-block;
      max-width: 60%;
      background-color: white;
      color: $grey_56;
      margin-top: -10px;
      padding: 0 20px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      &.long {
        max-width: 90%;
      }
    }
  }

  .border-center {
    margin-top: 10px;
    border: red 1px solid;
    position: relative;
  }
  .border-center:before { content: '';
    position: absolute;
    border-bottom: 2px green solid;
    width: 200px;
  }
  .marginTitle {
    margin-bottom: 20px;
  }
  .center-faker{
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  .small-text {
    font-size: 12px;
    font-weight: normal;
    color:$grey_56;
  }
  .forget-link {
    font-size: 12px;
    text-decoration: none;
    font-weight: normal;
    text-align: left;
    color:$grey_9b;
    &.pink{
      color:$pink;
    }
  }
  .error-confirm{
    color:$pink;
    margin-bottom: 20px;
    text-align: left;
    &.text-center {
      text-align: center !important;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
  .password-strength{
    color: $pink;
    margin-bottom: 10px;
    text-align: left;
  }
  .advise{
    text-align: left;
    color:$grey_9b;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .kosvg {
    padding-left: 20px;
    margin-right: 10px;
  }
  .light-strength{
    color: $pink;
  }
  .middle-strength{
    color: $orange;
  }
  .strong-strength{
    color: green;
  }

  .btn-google {
    background-color: transparent !important;
    color: $grey_56;
    border: 1px solid $grey_56 !important;
    a {
      color: $grey_56;
      text-decoration: none;
    }
  }
  .btn-facebook {
    background-color: blue !important;
    color: white;
    border: 0px;
    outline: none;
    a {
      color: white;
      text-decoration: none;
    }
  }

  .btn-popin {
    cursor: pointer;
    padding: 15px;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    outline: none;
    text-decoration: none;
    border:0;
    &:hover, &:focus {
      text-decoration: none;
      color: white;
      outline: none;
    }
    .btn-popin-login-submit {
      padding: 15px 100px;
    }
  }
  .form-group {
    margin-bottom: 25px;
    .eighty {
      width: 80%;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .loginFormError {
    margin-bottom: 10px;
    color: $red;
    font-weight: bold;
  }
  button {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  }

  input {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    width: 100%;
    &[type="checkbox"]{
      display: inline-block;
    }
  }
  .birthdate-line {
    text-align: left !important;
    .form-control{
      min-width: 80px;
      margin-right: 20px;
    }
  }
  .loader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,.5);
    display: none;
    @include transition(all,.5s,ease);
    &.active{
      display: flex;
      @include transition(all,.5s,ease);
    }
  }
}
