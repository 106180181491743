.section.video {
  .video_player {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    float: left;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .controls {
      position: absolute;
      top: 0;
      z-index: 1;
      color: white;
      display: flex;
      font-size: 70px;
      height: 100%;
      align-items: center;
      justify-content: center;
      width: 100%;
      left: 0;
      opacity: 0;
      @include transition(all, .5s, ease);
      .play, .pause {
        display: none;
        cursor: pointer;
        @include transition(all, .5s, ease);
        text-shadow: 2px 3px 3px rgba(0, 0, 0, .2);
      }
      .pause.active, .play.active {
        display: block;
        @include transition(all, .5s, ease);
      }
    }
    .controls.active {
      opacity: 1;
      @include transition(all, .5s, ease);
    }
    video {
      width: 100%;
    }
  }
}

.section.full_width {
  .full_width_img {
    float: left;
    width: 100%;
    height: 410px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.section.text {
  .img_header {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 500px;
  }
  h2 {
    font-weight: bold;
    color: black;
  }
  .faq {
    article {
      float: left;
      width: 100%;
      position: relative;
      .question {
        font-weight: bold;
        font-style: italic;
        margin: 15px 0;
      }
    }
  }
  .blockquote {
    .lines {
      float: left;
      width: 100%;
      height: 4px;
      .line1 {
        width: 15%;
        height: 100%;
        background: $grey_56;
        margin-right: 15px;
        float: left;
        border-radius: 200px;
      }
      .line2 {
        width: 5%;
        height: 100%;
        background: $grey_56;
        float: left;
        border-radius: 200px;
      }
    }
    .lines.right {
      display: flex;
      justify-content: flex-end;
      .line1 {
        width: 5%;
        height: 100%;
        background: $grey_56;
        margin-right: 15px;
        float: left;
        border-radius: 200px;
      }
      .line2 {
        border-radius: 200px;
        width: 15%;
        height: 100%;
        background: $grey_56;
        float: left;
      }
    }
    blockquote {
      float: left;
      width: 100%;
      font-style: italic;
      font-weight: bold;
      border: none;
      font-size: 30px;
      padding: 15px;
      margin: 0;
      text-align: center;
      p {
        &:before {
          content: '«';
          margin-right: 10px;
        }
        &:after {
          content: '»';
          margin-left: 10px;
        }
      }
    }
    .author {
      text-align: right;
      padding-bottom: 15px;
      width: 100%;
      float: left;
      text-transform: capitalize;
      color: $grey_ccc;
    }
  }
}

.section.double_img_left {
  .container {
    position: relative;
    .img_left {
      background-size: cover;
      background-position: top left;
      min-height: 500px;
      width: 33%;
      @media(max-width: 767px) {
        width: 100%;
        float:none;
        margin-bottom: 30px;
      }
      float: left;
    }
    .text {
      float: left;
      width: 66%;
      @media(max-width: 767px) {
        width: 100%;
        padding-bottom: 30px;
      }
      padding: 0 30px 230px 30px;
    }
    .img_bottom {
      background-size: cover;
      background-position: top left;
      min-height: 350px;
      width: 70%;
      @media(max-width: 767px) {
        width: 100%;
        bottom: 0;
        margin-bottom: 0;
      }
      bottom: 200px;
      right: 0;
      z-index: 1;
      float: right;
      position: relative;
      margin-bottom: -200px;
    }
  }
}

.section.double_img_right {
  .container {
    position: relative;
    .img_right {
      background-size: cover;
      background-position: top left;
      min-height: 500px;
      width: 33%;
      @media(max-width: 767px) {
        width: 100%;
        float:none;
        margin-bottom: 30px;
      }
      float: right;
    }
    .text {
      float: left;
      width: 66%;
      @media(max-width: 767px) {
        width: 100%;
        float:none;
        padding-bottom: 30px
      }
      padding: 0 30px 230px 30px;
    }
    .img_bottom {
      background-size: cover;
      background-position: top left;
      min-height: 350px;
      width: 70%;
      @media(max-width: 767px) {
        width: 100%;
        float: none;
        bottom: 0;
        margin-bottom: 0;
      }
      bottom: 200px;
      right: 0;
      z-index: 1;
      float: left;
      position: relative;
      margin-bottom: -200px;
    }
  }
}

.section.full_window {
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: top left;
  background-size: cover;
  height: 400px;
}

.section.half_width_img {
  .container {
    display: flex;
  }
  .text, .img {
    flex: 1;
    width: 50%;
  }
  .text {
    padding-right: 30px;
    blockquote {
      float: left;
      width: 100%;
      font-style: italic;
      font-weight: bold;
      border: none;
      font-size: 18px;
      padding: 15px;
      margin: 0;
      text-align: center;
      &:before {
        content: '«';
      }
      &:after {
        content: '»';
      }
    }
  }
  .img {
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
  }
}

.section.text_img_left {
  .container {
    position: relative;
    .img_left {
      background-size: cover;
      background-position: top left;
      min-height: 500px;
      width: 33%;
      @media(max-width: 767px) {
        width: 100%;
        float:none;
        margin-bottom: 30px;
      }
      float: left;
    }
    .text {
      float: left;
      width: 66%;
      @media(max-width: 767px) {
        width: 100%;
      }
      padding: 0 30px;
    }
    .img_bottom {
      background-size: cover;
      background-position: top left;
      min-height: 350px;
      width: 70%;
      @media(max-width: 767px) {
        width: 100%;
      }
      bottom: 200px;
      right: 0;
      z-index: 1;
      float: right;
      position: relative;
      margin-bottom: -200px;
    }
  }
}

.section.associated_experiences {
  h2 {
    text-align: left;
  }

  ul.experiences {
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;
    @media screen and (min-width: 768px) {
      display: flex;
    }

    li {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      display: flex;
      align-items: stretch;
      justify-content: center;
      color: white;
      margin-right: 15px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 15px;
      }
      @media screen and (min-width: 768px) {
        width: 33.3333%;
      }

      &:last-child {
        margin: 0;
      }

      .inner {
        text-align: center;
        padding: 30px 15px;
        width: 100%;
        float: left;
        background-color: rgba(0, 0, 0, 0.5);
        display: grid;
        h5 {
          text-transform: uppercase;
          font-weight: bold;
        }
        .icon {
          float: left;
          width: 100%;
          svg {
            height: 50px;
            g {
              fill: white;
            }
          }
        }
      }
    }
  }
}
