input[type="radio"],
input[type="checkbox"] {
  //display: none;
}

.label-radio:not(.control-label) {
  position: relative;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
}

.label-radio:not(.control-label) {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid $grey_ccc;
  }
}

.label-radio:not(.control-label) {
  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    background-color: $red;
    border-radius: 200px;
    left: 15px;
    transform: translateX(-50%) translateY(-50%);
    @include transition(all, .5s, ease);
  }
}

input[type="radio"]:checked + .label-radio:after,
input[type="checkbox"]:checked + .label-radio:after {
  opacity: 1;
  @include transition(all, .5s, ease);
}
