$icon-font-path: "../../fonts/bootstrap/" !default;
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import "~bootstrap-toggle/css/bootstrap2-toggle";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker";
@import "~font-awesome/scss/font-awesome";
@import "~flag-icon-css/sass/flag-icon";
@import "~jquery-bar-rating/dist/themes/fontawesome-stars-o.css";
@import "includes/variable";
@import "includes/fonts";
@import "includes/default";
@import "includes/header";
@import "includes/footer";
@import "includes/home";
@import "includes/experience";
@import "includes/stories";
@import "includes/ambassadeur";
@import "includes/radio";
@import "includes/search";
@import "includes/page";
@import "includes/recruitment";
@import "includes/single_story";
@import "includes/errors";
@import "includes/popin_login";
@import "includes/popin_ambassador";
@import "includes/datepicker";
@import "includes/profile";
@import "includes/reservation";
@import "includes/mangopay";
@import "includes/ambassadeur_profile";
