.search {
  footer {
    display: none;
  }
  .content.active{
    left: -225px;
    @include transition(all,.5s,ease);
  }
  .content {
    @include transition(all,.5s,ease);
    background-color: white;
    position: fixed;
    left: 0;
    z-index: 4;
    border-bottom: 1px solid $grey_light;
    .container {
      @media (max-width: 1199px) {
        width: 100%;
      }
      .search_form {
        float: left;
        width: 100%;
        padding: 30px 0;
        @media(max-width: 767px) {
          padding: 15px 0;
        }
        form {
          display: flex;
          justify-content: center;
          align-items: center;
          .form-group {
            margin: 0;
          }
          @media(min-width: 768px) {
            .form-group.theme_and_location {
              align-items: center;
              display: flex;
              label {
                margin: 0;
              }
            }
          }
          @media(max-width: 767px) {
            .form-group.theme_and_location {
              #theme {
                margin-bottom: 15px;
              }
            }
          }
          @media(min-width: 768px) {
            .form-group.map {
              align-items: center;
              display: flex;
            }
          }

          label {
            font-weight: bold;
            @media screen and (max-width: 767px) {
              text-align: center;
            }
          }
          label.france.active {
            svg {
              g {
                @include transition(all, .25s, ease);
                fill: $red;
              }
            }
            p {
              @include transition(all, .25s, ease);
              color: $grey_b2;
            }
          }
          label.france {
            @media(min-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
            p {
              @include transition(all, .25s, ease);
              margin: 0 0 0 10px;
            }
            svg {
              g {
                @include transition(all, .25s, ease);
                fill: $grey_b2;
              }
            }
          }
          .toggle.ios, .toggle-on.ios, .toggle-off.ios {
            border-radius: 20px;
            background: none;
          }
          .toggle.ios {
            border-color: $grey_light;
            .toggle-handle {
              border-radius: 20px;
              background-color: white;
              &:hover {
                background-color: white !important;
              }
            }
            .toggle-off {
              background-color: $grey_b2;
              &:hover {
                background-color: $grey_b2 !important;
              }
            }
            .toggle-on {
              background-color: $red;
              border-color: $red;
            }
          }
        }
      }
    }
  }
  .results {
    padding: 0;
    top: 95px;
    .line {
      float: left;
      width: 100%;
      min-height: 300px;
      .story {
        display: flex;
        align-items: stretch;
        justify-content: center;
        color: white;
        &:hover {
          .inner {
            .bg_img {
              @include transition(all, .5s, ease);
              @include scale(1.2);
            }
          }
        }
        .inner {
          width: 100%;
          float: left;
          position: relative;
          overflow: hidden;
          .bg_img {
            @include transition(all, .5s, ease);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
          .story_title {
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 5em;
            font-weight: 200;
            text-transform: uppercase;
          }
          .text {
            background-color: rgba(255, 255, 255, 0.8);
            color: black;
            min-height: 50%;
            float: left;
            width: 100%;
            padding: 15px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            display: grid;
            align-items: center;
            .title {
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 35px;
              width: auto;
            }
            p {
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 20px;
              width: auto;
              white-space: nowrap;
              font-size: 14px;
            }
          }
        }
      }
      .experience {
        display: flex;
        align-items: stretch;
        justify-content: center;
        color: white;
        &:hover {
          .inner {
            .bg_img {
              @include transition(all, .5s, ease);
              @include scale(1.2);
            }
          }
        }
        .inner {
          width: 100%;
          float: left;
          position: relative;
          overflow: hidden;
          .bg_img {
            @include transition(all, .5s, ease);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
          .text {
            text-align: center;
            padding: 30px 15px;
            width: 100%;
            float: left;
            background-color: rgba(0, 0, 0, 0.5);
            display: grid;
            align-items: center;
            z-index: 1;
            position: relative;
            height: 100%;
            h5 {
              font-size: 25px;
              @media(max-width: 1199px){
                font-size: 20px;
              }
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              text-align: center;
              color: white;
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              //white-space: nowrap;
              width: auto;
            }
            span.localization {
              font-size: 15px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 2.3px;
              color: #ffffff;
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 20px;
              white-space: nowrap;
              width: auto;
              margin-bottom: 10px;
            }
            .icon {
              float: left;
              width: 100%;
              svg {
                height: 50px;
                g {
                  fill: white;
                }
              }
            }
          }
        }
      }
      .ambassador {
        color: black;
        text-decoration: none;
        &:hover {
          .inner {
            .bg_img {
              @include transition(all, .5s, ease);
              @include scale(1.2);
            }
          }
        }
        .inner {
          width: 100%;
          float: left;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: flex-end;
          height: 300px;
          .bg_img {
            @include transition(all, .5s, ease);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
          .text {
            text-align: center;
            padding: 15px;
            width: 100%;
            float: left;
            background-color: rgba(255, 255, 255, 0.5);
            display: grid;
            align-items: center;
            z-index: 1;
            position: relative;
            height: 40%;
            h5 {
              text-transform: uppercase;
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: 15px;
              white-space: nowrap;
              width: auto;
              margin-bottom: 10px;
              margin-top: 0;
            }
            span.localization {
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 20px;
              white-space: nowrap;
              width: auto;
              margin-bottom: 10px;
            }
            .icon {
              float: left;
              width: 100%;
              svg {
                height: 50px;
                g {
                  fill: white;
                }
              }
            }
          }
        }
        .bottom {
          float: left;
          width: 100%;
          height: 10px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
      .post {
        margin-bottom: 30px;
        height: 300px;
      }
    }
    .map {
      /*height: calc(100vh - 194px) !important;
      position: fixed;
      top: 194px;
      right: 0;
      padding-bottom: 15px;*/
      z-index: 3;
      height: calc(100vh - 165px) !important;
      position: fixed;
      top: 165px;
      right: 0;
      padding: 0;
      #map {
        float: left;
        width: 100%;
        height: 100%;
        .infoBox {
          color: $grey_56;
          text-decoration: none;
          .row {
            margin-right: 0;
            margin-left: 0;
          }
          .title {
            text-transform: uppercase;
          }
          .author {
            display: flex;
            align-items: center;
            .profile_pic {
              border-radius: 200px;
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              height: 50px;
              width: 50px;
              float: left;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  .btn-white {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: center;
    color: #000000;
    padding: 15px 10px;
  }
  .see_more {
    margin-bottom: 40px;
  }
}
