.profile {
  .success-update{
    border-bottom: $grey_9b 1px solid;
    color: $red !important;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .password-strength{
    color: $pink;
    margin-bottom: 10px;
    text-align: left;
  }
  .advise{
    text-align: left;
    color:$grey_9b;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .kosvg {
    margin-right: 10px;
  }
  .light-strength{
    color: $pink;
  }
  .middle-strength{
    color: $orange;
  }
  .strong-strength{
    color: $green;
  }

  header {
    .streamerProfile {
      float: left;
      width: 100%;
      position: relative;
      text-align: center;
      color: black;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: center;
      h1 {
        text-transform: capitalize;
        font-weight: normal;
        font-size: 30px;
      }
      h2 {
        font-style: italic;
        font-weight: bold;
      }
    }
    .top_header {
      background-size: cover;
      .headline {
        color: white;
        &.black {
          color: black;
        }
        a {
          background: url('../../images/logo_white.svg') center center transparent no-repeat scroll;
        }
      }

      nav {
        ul {
          li {
            &:before {
              content: '|';
              color: white;
            }
            a {
              color: white;
              &.black {
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .message-profile {
    padding-bottom: 20px;
  }
  .btn-update {
    color: white;
    background-color: $red;
    border-radius: 200px;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 30px 10px 30px;
    border-bottom: 0px solid !important;
    margin-top: 10px;
    outline: none;
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: white !important;
      outline: none !important;
      text-decoration: none;
    }
  }

  .message {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .nopadding{
    padding: 0 !important;
  }
  .nomargin{
    margin: 0 !important;
  }
  .vignette {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 200px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: repeat;
    background-color: $grey-9b;
    .avatar {
      border-radius: 200px;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .oksvg {
    padding-left: 10px;
    margin-right: 10px;
    width: 35px;
    height: 35px;
  }
  .idcard {
    position: relative;
    border-radius: 200px;
    width: 65px;
    height: 60px;
    display: flex;
    align-items: center;
    background-position: center center;
    background-repeat: repeat;
    .avatar {
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .errorPassword {
    color: $red;
    font-weight: bold;
  }
  .infos {
    color: $grey_56;
    .marginTitle{
      margin-top: 30px;
      margin-bottom: 20px;
      @media(min-width: 767px) {
        margin-top: 60px;
      }
    }
    .tab-title {
      font-size: 2rem;
      @media(min-width: 767px) {
        font-size: 3rem;
      }
    }
    .tab-subtitle {
      font-size: 22px;
      font-weight: bold;
      @media(min-width: 767px) {
        margin-top: 50px;
      }
    }
    .completion{
      color: $red;
      font-size: 36px;
    }
  }
  .deleteLay{
    border-top: 1px solid $grey_ccc;
    padding-top: 20px;
    margin-top: 30px;
    a {
      &.disabled {
        color: $grey_ccc !important;
      }
      text-decoration: underline;
      color:$red;
    }
  }
  .left-menu {
    a {
      text-decoration: none;
      color: $grey_56;
      &.active {
        font-weight: bold;
      }
      font-size: 23px;
      &:hover, &:focus, &:active {
        outline: none;
        text-decoration: none;
      }
    }
  }
  .formPassword{
    padding-top: 20px;
    input{
      max-width: 370px;
    }
  }
  input {
    padding: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid $grey_56 !important;
    max-width: 400px;
    outline: 0;
    -webkit-box-shadow: none;
    &:focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  select {
    border: 1px solid $grey_56;
    border-radius: 2px;
    box-shadow: none;
    color: $grey_56;
    margin-right: 10px;
    outline: none;
    text-align: center;
    text-shadow: none;
  }
  .vich-image{
    display: none;
  }
  .listed_experiences {
    margin-top: 40px;
    margin-bottom: 40px;
    .experience {
      padding-bottom: 40px;
      padding-right: 30px;
      display: flex;
      align-items: stretch;
      justify-content: center;
      color: white;
      width: 360px;
      height: 360px;
      &:hover {
        .inner {
          .bg_img {
            @include transition(all, .5s, ease);
            @include scale(1.2);
          }
        }
      }
      .inner {
        width: 100%;
        float: left;
        position: relative;
        overflow: hidden;

        .btn-white {
          color: black;
          background-color: white;
          border-radius: 200px;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 3px;
          text-align: center;
          text-transform: uppercase;
          padding: 10px 30px 10px 30px;
          outline: none;
          border: none;
          text-decoration: none;
        }

        .bg_img {
          @include transition(all, .5s, ease);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
        .text {
          text-align: center;
          padding: 30px 15px;
          width: 100%;
          float: left;
          background-color: rgba(0, 0, 0, 0.5);
          display: grid;
          align-items: center;
          z-index: 1;
          position: relative;
          height: 100%;
          h5 {
            font-size: 25px;
            @media(max-width: 1199px){
              font-size: 20px;
            }
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            text-align: center;
            color: white;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            //white-space: nowrap;
            width: auto;
          }
          span.localization {
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 2.3px;
            color: #ffffff;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 20px;
            white-space: nowrap;
            width: auto;
            margin-bottom: 10px;
          }
          .icon {
            float: left;
            width: 100%;
            svg {
              height: 50px;
              g {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}
