.experience {
  .streamer {
    h2 {
      @media (max-width: 767px) {
        font-size: 30px;
      }
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.63;
      letter-spacing: 0.5px;
      text-align: center;
      color: #000000;
    }
    h1 {
      @media (max-width: 767px) {
        font-size: 28px;
      }
      font-size: 36px;
      font-weight: 500;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: 0.3px;
      text-align: center;
      color: black;
    }
  }

  .content_experience {
    .infos {
      h2 {
        text-align: left;
        font-weight: bold;
      }
      .vignette {
        position: relative;
        float: left;
        width: 100%;
        height: 400px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      ul.list_infos {
        list-style: none;
        margin: 0;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
          .libelle {
            font-weight: bold;
            margin-right: 5px;
          }
          .icon {
            color: $red;
            width: 40px;
            font-size: 24px;
            svg {
              g {
                color: $red;
              }
            }
          }
        }
      }
    }
    .description {
      p {
        font-size: 20px;
        @media screen and (max-width: 767px){
          font-size: 14px;
        }
      }
    }
    .availability {
      .date {
        float: left;
        width: 100%;
        margin-bottom: 100px;
        &:nth-child(even) {
          background-color: $grey_light;
        }
        .row {
          display: flex;
          align-items: center;
          &:nth-child(even) {
            background-color: $grey_light;
          }
          .placesLeft {
            color: $pink;
            display: flex;
            align-items: center;
            .icon {
              font-size: 2rem;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .othersAvailability {
      h2 {
        display: table;
        cursor: pointer;
        span {
          display: table-cell;
        }
        .toggleCalendar {
          color: $pink;
          font-size: 3rem;
          float: right;
          border: 1px solid $pink;
          width: 2rem;
          height: 2rem;
          border-radius: 200px;
          padding: 7px 5px;
          font-weight: 100;
          &:after {
            @include transition(all, .5s, ease);
            content: "-";
            display: block;
            position: relative;
            line-height: .5rem;
          }
          &:before {
            display: none;
          }
        }
      }
      h2.collapsed {
        .toggleCalendar {
          &:before {
            @include transition(all, .5s, ease);
            content: "+";
            display: block;
            position: relative;
            line-height: 1rem;
          }
          &:after {
            display: none;
          }
        }
      }
      .otherDates {
        float: left;
        padding: 20px 0;
        width: 100%;
        position: relative;
        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%);
          display: none;
        }
        .date {
          float: left;
          width: 100%;
          padding: 15px;
          &:nth-child(even) {
            background-color: $grey_light;
          }
          .row {
            display: flex;
            align-items: center;
            .placesLeft {
              color: $pink;
              display: flex;
              align-items: center;
              .icon {
                font-size: 2rem;
                margin-right: 5px;
              }
            }
            + .row {
              margin-top: 1rem;
            }
          }
        }
      }
    }
    .cgv-link {
      a {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: $grey_56;
        text-decoration: none;
        @include transition(all, .5s, ease);
        &:hover {
          color: $red;
          @include transition(all, .5s, ease);
        }
      }
    }
    .profil {
      .photo {
        float: left;
        width: 100%;
        display: flex;
        align-items: center;
        .vignette {
          cursor: pointer;
          position: relative;
          border-radius: 200px;
          padding: 10px;
          width: 150px;
          height: 150px;
          display: flex;
          align-items: center;
          margin: 0 auto 15px auto;
          background-position: center center;
          background-repeat: repeat;
          .avatar {
            border-radius: 200px;
            height: 100%;
            width: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
      .identity, .actions {
        float: left;
        width: 100%;
      }
      .actions {
        .section {
          padding: 10px 0;
          float: left;
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
        }
      }
      .dates {
        text-align: center;
        padding-bottom: 0;
      }
      .cgv {
        border-top: 1px solid black;
      }
      .tarif {
        text-align: center;
        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          .icon {
            color: $pink;
            font-size: 2rem;
            margin-right: 5px;
          }
        }
      }
    }
    .contact {
      text-align: center;
      a {
        margin: 30px auto 0 auto;
      }
    }
    .booking {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  .modal {
    .modal-content {
      .modal-header {
        border: none;
        h2 {
          text-align: center;
          font-weight: bold;
          color: $red;
        }
      }
      .modal-body {
        form {
          .form-group {
            margin-bottom: 25px;
            .eighty {
              width: 80%;
            }
          }
          .flex {
            display: flex;
            justify-content: space-between;
          }
          input, select {
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;
            border-radius: 0;
            outline: none;
            box-shadow: none;
          }
          .form-control {
            @include transition(all, .5s, ease);
          }
          .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
            background-color: transparent;
            opacity: .2;
            @include transition(all, .5s, ease);
          }
        }
      }
    }
  }
  .btn-book {
    padding: 17px 58px;
  }
}
